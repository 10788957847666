import {AxiosResponse} from 'axios';
import ApiService from '@/services/_helper/api.service';

export default class SettingsService {
  static getSystemSettings(): Promise<AxiosResponse> {
    return ApiService.get('/force-update-version');
  }
  static updateAndroidSystemSettings(payload: {params: {version: string}}): Promise<AxiosResponse> {
    return ApiService.put(`/force-update-version/android`, payload.params);
  }
  static updateIOSSystemSettings(payload: {params: {version: string}}): Promise<AxiosResponse> {
    return ApiService.put(`/force-update-version/ios`, payload.params);
  }
}
